import {Burger, Drawer} from '@mantine/core';
import React from 'react';
import {Link} from 'react-scroll';
import LanguageToggle from "./LanguageToggle";
import {useIntl} from "react-intl";

const Header = () => {
    //const theme = useMantineTheme();
    const [opened, setOpened] = React.useState(false);
    const title = opened ? 'Close navigation' : 'Open navigation';
    const intl = useIntl();

    const navLinks = [
        {id: 'header.menu.about_me', section: 'section-one'},
        // {id: 'header.menu.tell_me_more', section: 'section-four'},
        {id: 'header.menu.contact_me', section: 'section-five'},
    ];

    return (
        <header>
            <div className="content-desktop">
                <div>
                    {/*<Badge size="xl" radius={10} color="grey">514-582-6431</Badge>*/}
                </div>
                <div className="navbar">
                    {navLinks.map((link) => (
                        <div key={link.id} className="navbar-item">
                            <Link to={link.section} smooth duration={500}>
                                {intl.formatMessage({id: link.id})}
                            </Link>
                        </div>
                    ))}
                    <LanguageToggle/>
                </div>
            </div>

            <div className="content-mobile" style={{backgroundColor:"black"}}>
                <div className="burger-button">
                    <Burger
                        opened={opened}
                        onClick={() => setOpened((o) => !o)}
                        title={title}
                        size="sm"
                        color="white"
                    />
                </div>

                <Drawer
                    position="right"
                    title="Menu"
                    // padding="xl"
                    opened={opened}
                    size="60%"
                    onClose={() => setOpened(false)}
                >
                    <div className="menu" >
                        {navLinks.map((link) => (
                            <div key={link.id} className="menu-items">
                                <Link to={link.section} smooth duration={500}>
                                    {intl.formatMessage({id: link.id})}
                                </Link>
                            </div>
                        ))}
                        <LanguageToggle color="black" />
                    </div>
                </Drawer>
            </div>
        </header>
    );
};

export default Header;

const redirectToLink = (link: string): void => {
    window.open(link, '_blank');
};
