import enMessages from './locales/en_US'
import frMessages from './locales/fr_CA'

export interface IMessage {
  messages: object
  locale: any
}

export interface LocaleConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: IMessage
}

// https://www.keypup.io/blog/react-extend-react-intl-with-our-own-context-and-markup
// https://formatjs.io/docs/getting-started/installation
const appLocales: LocaleConfig = {
  en: {
    messages: enMessages,
    locale: 'en-US',
  },
  fr: {
    messages: frMessages,
    locale: 'fr-CA',
  },
}

export default appLocales
