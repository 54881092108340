import {Title, Text, Container, Paper, Group, Space} from '@mantine/core';
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";

const SectionTwo = () => {
    const [value, setValue] = useState<Date | null>(new Date());
    const intl = useIntl();
    const [content, setContent] = useState<any>({
        content: {
            text: ""
        }
    });

    useEffect(() => {
        // Define a function to fetch the content from API
        const fetchContent = async () => {
            try {
                if (value) {
                    const formattedDate = value.toISOString().split('T')[0]; // Format the date to 'YYYY-MM-DD'
                    const response = await fetch(`https://api.moontracker.ca/just_for_today/current_day?dateParam=${formattedDate}`);
                    const data = await response.json();

                    if (data && data.content) {
                        setContent(data.content);
                    }
                }
            } catch (error) {
                console.error("There was an error fetching the content:", error);
            }
        };

        // Invoke the fetch function
        fetchContent();
    }, [value]);  // The empty array means this useEffect will run once when the component mounts.


    return (
        <section id="section-two">
            <Container>
                <Paper withBorder p="lg" radius="md" shadow="md">
                    <Group mb="xs">
                        <Title fz="md" fw={500}>
                            {intl.formatMessage({id: "stepTwo.title"})}
                        </Title>
                    </Group>
                    <Group position="left" w={300}>
                        <Text>
                        {value?.toLocaleDateString('fr-CA', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}
                        </Text>
                    </Group>
                    {content && (
                        <>
                            <Title order={3} mt="md">
                                {content?.title ?? ""}
                            </Title>
                            <Text size="md" fs="italic">
                                "{content?.subtitle ?? ""}"
                            </Text>
                            <Space h="md"/>
                            <Text fw={700}>
                                {content?.source ?? ""}
                            </Text>
                            <Space h="md"/>
                            <Text size="lg">
                                {content?.text ?? ""}
                            </Text>
                            <Space h="md"/>
                            <Text fw={600} size="lg">
                                {content?.just_for_today ?? ""}
                            </Text>
                        </>
                    )}
                </Paper>
            </Container>
        </section>
    )
};

export default SectionTwo;
