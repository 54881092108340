import SectionOne from '../Components/SectionOne';
import SectionTwo from '../Components/SectionTwo';
import SectionThree from '../Components/SectionThree';
import SectionEmbedApple from '../Components/SectionEmbedApple';
import SectionEmbedSpotify from '../Components/SectionEmbedSpotify';
import SectionFive from '../Components/SectionFive';
import {EmailBanner} from "../Components/EmailBanner";
import About from '../Components/About';
import React from "react";

const Content = () => {
    return (
        <>
            <About />
            <SectionOne />
            <SectionEmbedSpotify />
            <SectionEmbedApple />
            <EmailBanner />
            <SectionTwo />
            <SectionThree />
            {/*<SectionFour />*/}
            <SectionFive />
            { /* Other sections .. */ }
        </>
    )
};

export default Content;
