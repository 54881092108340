import {EmailBanner} from "../../Components/EmailBanner";

const messages = {
    Unknown: "Inconnu",
    header: {
        menu: {
            about_me: 'En savoir plus',
            tell_me_more: 'En savoir plus',
            contact_me: 'Contactez-nous',
        }
    },
    about_me: {
        title: 'Podcast Anonyme',
        subTitle: 'Une initiative du meeting Le Spot de Narcotiques Anonymes de Chambly',
        subSubTitle: 'Seul on va plus vite, ensemble on va plus loin',
        work_with_me: 'Participer au podcast',
    },
    stepOne: {
        title: 'À propos de moi',
        subTitle: 'Je suis passionné par la création de logiciels qui améliorent la vie de ceux qui m\'entourent.',
        text: {
            one: {
                text: "Bienvenue sur la page du Podcast Anonyme. Il s’agit d’une réflexion sur la méditation du \"Juste pour aujourd’hui\" telle qu’on peut la lire dans la publication du même nom de Narcotiques Anonymes. C’est un outil pour connaître la perspective d’un.e membre sur le sujet du jour, et peut-être, nous l’espérons, un tremplin pour y songer un peu plus chacun de notre côté. Si vous avez envie d’aller leur donner de l’amour, cette idée nous vient d’une initiative états-unienne qui existe déjà, il s’agit du Anonymous Podcast. Pour tous les membres qui ne parle pas anglais, nous voici!",
            },
            two: {
                text: 'Pour participer au podcast n’hésitez pas à remplir le formulaire , nous nous ferons un plaisir de vous contacter selon les besoins. Ne soyez pas intimidés, si vous ne vous sentez pas d’attaque pour partager une réflexion, vous pouvez sélectionner l\'option "Lecture du Juste pour aujourd\'hui" dans le formulaire. Toutes ces tâches seront appréciées.',
            },
            three: {
                text: 'Nous souhaitons que ce projet enrichisse le rétablissement de notre fraternité et vous demandons humblement de faire circuler la nouvelle et les liens appropriés.',
            },
            four: {
                text: 'Au plaisir de passer la prochaine année avec vous!',
            },
            five: {
                text: 'Le comité du Podcast Anonyme du meeting Le Spot de Chambly',
            },
            six: {
                text: 'Toujours là pour aider',
            }
        }
    },
    stepTwo: {
        title: 'Juste pour aujourd\'hui',
    },
    EmailBanner: {
        title: 'Une minute...',
        text: {
            one: 'Abonnez-vous à notre info lettre!',
            two: 'Pour les nouvelles fraîches de l’initiative, nous nous engageons à ne jamais vous bombarder de courriels!',
        },
        button: "S'abonnez",
    },
    stepThree: {
        title: 'Besoin d\'aide?',
        text: 'Voici aussi un lien où tu pourras trouver une réunion près de chez toi',
        text_two: 'Si tu as besoin d\'aide, voici aussi un numéro de téléphone pour notre ligne d\'entraide : ',
        button: "naquebec.org",
    },
    stepFour: {
        title: 'Mes services',
        button: 'Découvrez',
        cards: {
            one: {
                title: 'Développement de logiciels sur mesure',
                text: 'Création de solutions sur mesure qui correspondent parfaitement aux besoins et objectifs spécifiques de vos clients. De la conception au lancement, je fournis des services de développement de bout en bout pour créer des applications robustes, évolutives et innovantes qui résolvent les problèmes du monde réel.'
            },
            two: {
                title: 'Consultation en technologie et planification stratégique',
                text: 'Utilisez votre compréhension profonde des tendances technologiques et des perspectives de l\'industrie pour offrir une consultation stratégique. Aidez les clients à identifier les bonnes technologies et méthodologies pour atteindre leurs objectifs, tout en garantissant l\'alignement avec les stratégies commerciales actuelles et futures.'
            },
            three: {
                title: 'Maintenance et support',
                text: 'Offrez des services de support et de maintenance continus pour garantir que les systèmes de vos clients restent à jour et alignés avec les normes de l\'industrie en évolution. Cela pourrait inclure le dépannage, la mise en œuvre de mises à jour, l\'optimisation des performances et la fourniture de formation pour garantir que vos clients sont toujours à la pointe des avancées technologiques.'
            }
        }
    },
    stepFive: {
        text: 'Tous les projets de Narcotiques Anonymes sont riches des expériences, forces et espoirs de tous ses membres. Nous serions reconnaissants que vous remplissiez notre formulaire afin de participer au projet de la manière qui vous convient le mieux. Merci de faire partie du NOUS.',
        title: 'Informations de contact',
        subTitle: 'Entrer en contact',
        name: {
            label: 'Votre nom',
            placeholder: 'Entrez votre nom',
        },
        email: {
            label: 'Votre courriel',
            placeholder: 'Entrez votre courriel',
        },
        phone: {
            label: 'Votre téléphone',
            placeholder: 'Entrez votre téléphone',
        },
        subject: {
            label: 'Sujet',
            placeholder: 'Entrez votre sujet',
        },
        type: {
            label: 'Type de participation',
            placeholder: 'Faite une sélection',
            partage: 'Partagez au sujet du juste pour aujourd\'hui',
            lecture: 'Lecture du juste pour aujourd\'hui',
        },
        message: {
            label: 'Message',
            placeholder: 'Veuillez inclure toutes les informations pertinentes',
        },
        calendar: 'Votre date d\'abstinence',
        send_message: 'Envoyez votre formulaire',
        sideBar: {
            email:{
                label: 'Courriel',
                description: 'info@podcastanonyme.com',
            },
            phone: {
                label: 'Téléphone',
                description: '+1 (514) 582-6431',
            },
            address: {
                label: 'Adresse',
                description: 'Église Très-Saint-Coeur-de-Marie\n' +
                    '2390 Av Bourgogne, Chambly, QC, Canada, J3L 2A4',
            },
            meeting: {
                label: 'Réunion',
                description: 'Les mercredis 19H30 – 21H00',
            }
        }
    },
    footer: {
        title: 'PODCAST ANONYME',
        address: 'Adresse:  Église Très-Saint-Coeur-de-Marie 2390 Av Bourgogne, Chambly, QC, Canada, J3L 2A4',
        phone_email: 'Téléphone: (514) 582-6431 | Email: info@podcastanonyme.com',
        thanks: "Nous tenons à exprimer notre profonde gratitude envers Douglas L. de the \"Anonymous Podcast\" pour son précieux soutien. Nous vous encourageons vivement à aller lui donner un peu d'amour. "
    }
}

export default messages
