import {Text, Container, Anchor, MediaQuery, Button, Space} from '@mantine/core';
// import { useNavigate } from "react-router-dom";
import { MdOutlineArrowDownward } from "react-icons/md";
import { Link } from 'react-scroll';
import {useIntl} from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpotify as faSpotify, faApple as faApple} from '@fortawesome/free-brands-svg-icons';
const About = () => {
    //const theme  useMantineTheme();
    const intl = useIntl();
    // const navigate = useNavigate();

    const gotoAbout = () => {
        // navigate("/about");

    }
    return (
        <section id="about">
            <Container fluid>

                <div className="about-content">

                    <div style={{ marginBottom: 15 }}>
                        <Text transform="uppercase" weight={500} color="blue">
                            {intl.formatMessage({id: "about_me.subTitle"})}
                        </Text>
                    </div>

                    <div style={{ marginBottom: 15 }}>
                        <Text>
                            <MediaQuery query="(max-width: 768px)" styles={{ fontSize: '2.8rem !important' }}>
                                <h1 className="title">{intl.formatMessage({id: "about_me.title"})}</h1>
                            </MediaQuery>
                        </Text>
                    </div>

                    <div style={{ marginBottom: 25 }}>
                        <Text size="xl" color="white">
                            {intl.formatMessage({id: "about_me.subSubTitle"})}.
                        </Text>
                        <div style={{ marginTop: 25 }}>
                        <span style={{ marginRight: 15 }}>
                            <a href={"https://open.spotify.com/show/07aaBWn4iyhVgggxPTuQO9"} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSpotify as IconProp} color="white" size="2xl"/>
                            </a>
                        </span>
                        <span style={{ marginRight: 15 }}>
                            <a href={"https://podcasts.apple.com/us/podcast/podcast-anonyme-juste-pour-aujourdhui/id1710425368"} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faApple as IconProp} color="white" size="2xl"/>
                            </a>
                         </span>
                        </div>
                    </div>
                    <div className="buttons">
                        <Link to="section-one" smooth duration={500}>
                            <Button color="blue" rightIcon={<MdOutlineArrowDownward size={16} />} onClick={gotoAbout} radius="lg" size="md">{intl.formatMessage({id: "header.menu.tell_me_more"})}</Button>
                        </Link>

                        <Link to="section-five" smooth duration={500}>
                            <Button variant="default" radius="lg" size="md">{intl.formatMessage({id: "about_me.work_with_me"})}</Button>
                        </Link>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default About;
