import { Carousel } from '@mantine/carousel';
import {Text, Container, useMantineTheme, Title, Image} from '@mantine/core';
import '../Styles/SectionOne.scss';
import {useIntl} from "react-intl";
import ideaLaunchImage from "../Images/racinepilote.jpeg";
// import teamImage from '../Images/team-idea.svg';
// import imageOfRacinePilote from "../Images/racinepilote_developer.jpeg";

const SectionEmbedApple= () => {
    const theme = useMantineTheme();
    const intl = useIntl();

    return (
        <section id="section-one">
            <Container>
                <Text color="black" align="justify" mb="15px">
                    Vous pouvez écouter nos podcasts sur la plateforme Apple Podcast en cliquant sur le bouton ci-dessous.
                </Text>
                <iframe id="embedPlayer" src="https://embed.podcasts.apple.com/us/podcast/podcast-anonyme-juste-pour-aujourdhui/id1710425368?itsct=podcast_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="450px" frameBorder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" style={{width: "100%", maxWidth: "960px", overflow: "hidden", borderRadius: "10px", transform: "translateZ(0px)", animation: "2s 6 loading-indicator", backgroundColor: "rgb(228, 228, 228)"}}></iframe>
            </Container>
        </section>
    );
};

export default SectionEmbedApple;
