import React from 'react'
import { createStyles, UnstyledButton, Text, Center, Group } from '@mantine/core'
import { upperFirst } from '@mantine/hooks'
import { Language } from 'tabler-icons-react'
import useLanguage from '../../hooks/useLanguage'

const useStyles = createStyles(theme => ({
  control: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.white : theme.colors.gray[0],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 1000,
    paddingLeft: theme.spacing.sm,
    paddingRight: 4,
    width: 36,
    height: 0,
  },

  iconWrapper: {
    height: 28,
    width: 28,
    borderRadius: 28,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.yellow[4] : theme.colors.dark[4],
    color: theme.colorScheme === 'dark' ? theme.black : theme.colors.blue[9],
  },

  value: {
    lineHeight: 1,
  },
}))

const LanguageToggle = ({color="white"}:any) => {
  const { classes } = useStyles()
  const { language, toggleChangeLanguage } = useLanguage()
  const Icon = language === 'fr' ? Language : Language

  return (
    <Group position="center" >
      <UnstyledButton
        aria-label="Toggle theme"
        className={classes.control}
        onClick={() => toggleChangeLanguage()}
        title="Ctrl + J"
      >
        <Text size="sm" className={classes.value} style={{color: color}}>
          {upperFirst(language === 'fr' ? 'EN' : 'FR')}
        </Text>

        {/*<Center className={classes.iconWrapper}>*/}
        {/*  <Icon size={18} />*/}
        {/*</Center>*/}
      </UnstyledButton>
    </Group>
  )
}

export default LanguageToggle
