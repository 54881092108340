import {Text, Title, TextInput, Button, Image, Container} from '@mantine/core';
import image from './image.svg';
import classes from './EmailBanner.module.css';
import React, {useState} from "react";
import {useIntl} from "react-intl";
const api_url = process.env.REACT_APP_API ?? "https://admin.podcastanonyme.com";

export function EmailBanner() {
    const intl = useIntl();
    const [formData, setFormData] = useState({
        email: '',
    });
    function handleInputChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const { name, value } = event.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }
    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        // Send data to server
        const response = await fetch(`${api_url}/mailing_lists`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({...formData}),
        });

        if (response.ok) {
            alert("Message sent successfully!");
            setFormData({ email: '' })
        } else {
            alert("There was an error sending your message. Please try again later.");
        }
    }
    return (
        <Container>
        <div className={classes.wrapper}>
            <div className={classes.body}>
                <Title
                    className={classes.title}>
                    {intl.formatMessage({id: "EmailBanner.title"})}
                </Title>
                <Text fw={500} fz="lg" mb={5}>
                    {intl.formatMessage({id: "EmailBanner.text.one"})}
                </Text>
                <Text fz="sm" c="dimmed">
                    {intl.formatMessage({id: "EmailBanner.text.two"})}
                </Text>

                <form onSubmit={handleSubmit}>
                <div className={classes.controls}>
                    <TextInput
                        placeholder={intl.formatMessage({id: "stepFive.email.placeholder"})}
                        classNames={{ input: classes.input, root: classes.inputWrapper }}
                        required
                        onChange={handleInputChange}
                        name="email"
                    />
                    <Button type="submit" className={classes.control}> {intl.formatMessage({id: "EmailBanner.button"})}</Button>
                </div>
                </form>
            </div>
            <Image src={image} className={classes.image} />
        </div>
        </Container>
    );
}
