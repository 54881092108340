import { Carousel } from '@mantine/carousel';
import {Text, Container, useMantineTheme, Title, Image} from '@mantine/core';
import '../Styles/SectionOne.scss';
import {useIntl} from "react-intl";
import ideaLaunchImage from "../Images/racinepilote.jpeg";
// import teamImage from '../Images/team-idea.svg';
// import imageOfRacinePilote from "../Images/racinepilote_developer.jpeg";

const SectionEmbedSpotify= () => {
    const theme = useMantineTheme();
    const intl = useIntl();



    return (
        <section id="section-one">
            <Container>
                <Text color="black" align="justify" mb="15px" >
                    Vous pouvez écouter nos podcasts sur la plateforme Spotify en cliquant sur le bouton ci-dessous.
                </Text>
                <iframe
                    style={{ borderRadius: '12px' }}
                    src="https://open.spotify.com/embed/show/07aaBWn4iyhVgggxPTuQO9?utm_source=generator"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    allowFullScreen
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy">
                </iframe>
            </Container>
        </section>
    );
};

export default SectionEmbedSpotify;
