import {
    Paper,
    Text,
    TextInput,
    Textarea,
    Button,
    Group,
    SimpleGrid,
    createStyles,
    Container,
    rem, Select, Menu, UnstyledButton, Checkbox, Modal,
} from '@mantine/core';
import {ContactIconsList} from './ContactIcons/ContactIcons';
import {useIntl} from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import React, {useState} from "react";
import {DatePicker} from "@mantine/dates";
import {IconChevronDown, IconX} from "@tabler/icons-react";
import classesTow from './LanguagePicker.module.css';
import 'dayjs/locale/fr-ca';
import {useDisclosure} from "@mantine/hooks";

const api_url = process.env.REACT_APP_API ?? "https://admin.podcastanonyme.com";
const useStyles = createStyles((theme) => {
    const BREAKPOINT = theme.fn.smallerThan('sm');

    return {
        wrapper: {
            display: 'flex',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
            borderRadius: theme.radius.lg,
            padding: rem(4),
            border: `${rem(1)} solid ${
                theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2]
            }`,

            [BREAKPOINT]: {
                flexDirection: 'column',
            },
        },

        form: {
            boxSizing: 'border-box',
            flex: 1,
            padding: theme.spacing.xl,
            paddingLeft: `calc(${theme.spacing.xl} * 2)`,
            borderLeft: 0,

            [BREAKPOINT]: {
                padding: theme.spacing.md,
                paddingLeft: theme.spacing.md,
            },
        },

        fields: {
            marginTop: rem(-12),
        },

        fieldInput: {
            flex: 1,

            '& + &': {
                marginLeft: theme.spacing.md,

                [BREAKPOINT]: {
                    marginLeft: 0,
                    marginTop: theme.spacing.md,
                },
            },
        },

        fieldsGroup: {
            display: 'flex',

            [BREAKPOINT]: {
                flexDirection: 'column',
            },
        },

        contacts: {
            boxSizing: 'border-box',
            position: 'relative',
            borderRadius: theme.radius.lg,
            backgroundColor: theme.colors.blue[theme.colorScheme === 'dark' ? 6 : 4],
            // backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            border: `${rem(1)} solid transparent`,
            padding: theme.spacing.xl,
            flex: `0 0 ${rem(280)}`,

            [BREAKPOINT]: {
                marginBottom: theme.spacing.sm,
                paddingLeft: theme.spacing.md,
            },
        },

        title: {
            marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,

            [BREAKPOINT]: {
                marginBottom: theme.spacing.xl,
            },
        },

        textCondition: {
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontSize: '1rem',
            cursor: 'pointer',
            [BREAKPOINT]: {
                marginBottom: theme.spacing.xl,
            },
        },

        control: {
            [BREAKPOINT]: {
                flex: 1,
            },
        },


    };
});

const SectionFive = () => {
    //const theme = useMantineTheme();
    const [value, setValue] = useState<Date | null>(new Date());
    const [opened, setOpened] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // Add this state
    const [openedCondition, {open, close}] = useDisclosure(false);
    const [check, setCheck] = useState(false);

    const {classes} = useStyles();
    const intl = useIntl();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        type: '',
        cleanDate: '',
        conditionAccepted: false,
        captcha: null
    });

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {name, value} = event.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    }

    function handleCaptchaChange(value: any) {
        setFormData(prevData => ({...prevData, captcha: value}));
    }

    function handleChangeSelect(value: any) {
        setFormData(prevData => ({...prevData, type: value}));
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        // Validate that CAPTCHA was completed
        if (!formData.captcha) {
            alert("Please complete the CAPTCHA!");
            return;
        }

        setIsSubmitting(true); // Set isSubmitting to true before API call

        const response = await fetch(`${api_url}/contact_us`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({...formData, cleanDate: value?.toISOString().split('T')[0], conditionAccepted: check}),
        });

        if (response.ok) {
            alert("Message sent successfully!");
        } else {
            alert("There was an error sending your message. Please try again later.");
        }
        setIsSubmitting(false); // Set isSubmitting back to false after API call
        setValue(null);
        setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
            type: '',
            cleanDate: '',
            conditionAccepted: false,
            captcha: null
        })
    }

    return (
        <>
            <Modal opened={openedCondition} onClose={close} title="Authentication" centered>
                    Autorisation de diffusion
                    En cochant cette case, je consens à céder gracieusement mes droits de diffusion
                    au Podcast Anonyme sur les plateformes numériques.
                    De plus en cochant je consent à ce que mon nom, prénom et adresse courriel
                    soient utilisés à des fins de communication avec le Podcast Anonyme.
            </Modal>
        <section id="section-five">
            <Container>

                <Text color="black" align="justify" mb="15px">
                    {intl.formatMessage({id: "stepFive.text"})}
                </Text>
                <Paper shadow="md" radius="lg">
                    <div className={classes.wrapper}>
                        <div className={classes.contacts}>
                            <Text fz="lg" fw={700} className={classes.title} c="#fff">
                                {intl.formatMessage({id: "stepFive.title"})}
                            </Text>

                            <ContactIconsList variant="white"/>
                        </div>

                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Text fz="lg" fw={700} className={classes.title}>
                                {intl.formatMessage({id: "stepFive.subTitle"})}
                            </Text>
                            <div className={classes.fields}>
                                <SimpleGrid cols={2} breakpoints={[{maxWidth: 'sm', cols: 1}]}>
                                    <TextInput
                                        label={intl.formatMessage({id: "stepFive.name.label"})}
                                        placeholder={intl.formatMessage({id: "stepFive.name.placeholder"})}
                                        required
                                        onChange={handleInputChange}
                                        name="name"
                                        value={formData.name}  // Add this line
                                    />
                                    <TextInput
                                        label={intl.formatMessage({id: "stepFive.email.label"})}
                                        placeholder={intl.formatMessage({id: "stepFive.email.placeholder"})}
                                        required
                                        onChange={handleInputChange}
                                        name="email"
                                        value={formData.email}  // Add this line
                                    />
                                </SimpleGrid>
                                <TextInput
                                    mt="md"
                                    label={intl.formatMessage({id: "stepFive.phone.label"})}
                                    placeholder={intl.formatMessage({id: "stepFive.phone.placeholder"})}
                                    required
                                    onChange={handleInputChange}
                                    name="phone"
                                    value={formData.phone}  // Add this line

                                />
                                <Select data={[
                                    {value: 'partage', label: intl.formatMessage({id: "stepFive.type.partage"})},
                                    {value: 'lecture', label: intl.formatMessage({id: "stepFive.type.lecture"})},
                                ]}
                                        label={intl.formatMessage({id: "stepFive.type.label"})}
                                        placeholder={intl.formatMessage({id: "stepFive.type.placeholder"})}
                                        onChange={handleChangeSelect}
                                        required
                                        value={formData.type}  // Add this line to make it a controlled component
                                />
                                <Textarea
                                    mt="md"
                                    label={intl.formatMessage({id: "stepFive.message.label"})}
                                    placeholder={intl.formatMessage({id: "stepFive.message.placeholder"})}
                                    minRows={3}
                                    onChange={handleInputChange}
                                    name="message"
                                    value={formData.message}  // Add this line

                                />
                                <p className={classesTow.labelTwo}> {intl.formatMessage({id: "stepFive.calendar"})}</p>
                                <Group position="left">
                                    <Menu
                                        onOpen={() => setOpened(true)}
                                        onClose={() => setOpened(false)}
                                        radius="md"
                                        width="target"
                                        withinPortal
                                    >
                                        <Menu.Target>
                                            <UnstyledButton className={classesTow.control}
                                                            data-expanded={opened || undefined}>
                                                {!value ? <span
                                                    className={classesTow.label}>  {intl.formatMessage({id: "stepFive.calendar"})}</span> : <>
                                                    <UnstyledButton onClick={() => setValue(null)}><IconX size="1rem"
                                                                                                          stroke={2.5}/></UnstyledButton><span> {value?.toISOString().split('T')[0]} </span></>}
                                                <IconChevronDown size="1rem" className={classesTow.icon} stroke={2.5}/>
                                            </UnstyledButton>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            {
                                                intl.locale === 'fr-CA' ? (
                                                        <DatePicker locale="fr-ca" value={value} onChange={setValue}/>) :
                                                    (<DatePicker locale="en-ca" value={value} onChange={setValue}/>)
                                            }
                                        </Menu.Dropdown>
                                    </Menu>
                                </Group>
                                <Group position="left" mt="md" mb="md">
                                    <ReCAPTCHA
                                        sitekey="6Lc0AWYoAAAAAEiK-OyFmICGm41GSkOSkCu_uj98"
                                        onChange={(value: any) => handleCaptchaChange(value)}
                                    />
                                </Group>
                                <Group position="left">
                                    <Checkbox checked={check} onChange={() => setCheck(!check)}
                                    />
                                    <Text className={classes.textCondition} onClick={open}>J'accepte les conditions (Cliquez ici)</Text>

                                </Group>
                                <Group position="right" mt="md">
                                    <Button color="blue" type="submit" className={classes.control}
                                            disabled={isSubmitting || !check}>
                                        {intl.formatMessage({id: "stepFive.send_message"})}
                                    </Button>
                                </Group>
                            </div>
                        </form>
                    </div>
                </Paper>
            </Container>
        </section>
        </>
    );

};

export default SectionFive;
