const messages = {
    Unknown: "Unknown",
    header:{
        menu:{
            about_me: 'Tell Me More',
            tell_me_more: 'Tell Me More',
            contact_me: 'Contact Us',
        }
    },
    about_me:{
        title: 'Podcast Anonyme',
        subTitle: 'An initiative of the Narcotics Anonymous meeting Le Spot in Chambly',
        subSubTitle: 'Alone we go faster, together we go further',
        work_with_me: 'To be a guest',
    },
    stepTwo: {
        title: 'Juste pour aujourd\'hui',
    },
    stepOne:{
        title: 'About Us',
        subTitle: 'I am passionate about building software that improves the lives of those around me.',
        text: {
            one: {
                text: 'Welcome to the page of the Podcast Anonyme. It is a commentary and discussion in French on the Narcotics Anonymous literature, namely the Just For Today (Juste pour aujourd’hui). If you would like to practice your French, our podcast is a good place to tune in. This idea comes from the United States where you can hear an english version at the Anonymous Podcast on Spotify. Pay them a visit! ',
            },
            two: {
                text: 'If you speak enough French and would like to participate (either to read or share your experience, strength and hope), please fill out our form and we will get back to you according to our needs. ',
            },
            three: {
                text: 'We hope that this project will enrich our collective recovery and humbly ask that you share this podcast with anyone interested, please and thank you. ',
            },
            four: {
                text: 'To the great pleasure of spending this coming year with all of you!',
            },
            five: {
                text: 'The Podcast Anonyme committee of the Spot meeting in Chambly.',
            },
            six: {
                text: 'Toujours là pour aider',
            }
        }
    },
    EmailBanner: {
        title: 'Hold on!',
        text: {
            one: 'Subscribe to our newsletter !',
            two: 'For all the latest news please sign up. We promise to be reasonable!',
        },
        button: "Subscribe",
    },
    stepThree: {
        title: 'Need help?',
        text: 'Here is also a link where you can find a meeting near you.',
        text_two: 'If you need assistance, here is a phone number for our helpline : ',
        button: "naquebec.org",
    },
    stepFour:{
        title: 'My Services',
        button: 'Find out',
        cards:{
            one:{
                title: 'Custom Software Development',
                text: ' Crafting tailor-made solutions that align perfectly with your clients\' specific needs and goals. From concept to launch, provide end-to-end development services to create robust, scalable, and innovative applications that solve real-world problems.',
                badge: 'Customization',

            },
            two:{
                title: 'Technology Consultation and Strategy Planning',
                text: 'Utilize your deep understanding of technology trends and industry insights to offer strategic consultation. Assist clients in identifying the right technologies and methodologies to achieve their objectives, while ensuring alignment with current and future business strategies.',
                badge: 'Consultation',

            },
            three:{
                title: 'Maintenance and Support',
                text: 'Offer ongoing support and maintenance services to ensure that clients\' systems remain up to date and aligned with evolving industry standards. This could include troubleshooting, implementing updates, optimizing performance, and providing training to ensure that your clients are always at the forefront of technological advancements.',
                badge: 'Maintenance',
            }
        }
    },
    type: {
        label: 'Type of participation',
        placeholder: 'Make a selection',
        partage: 'Sharing on the Just For Today',
        lecture: 'Reading the Just For Today',
    },
    stepFive:{
        text: 'All Narcotiques Anonymes projects are rich of our collective experience, strength and hope. We would greatly appreciate if you filled out our form to participate in the way that suits you best. Thank you for being part of the We. ',
        title: 'Contact information',
        subTitle: 'Get in touch .',
        calendar: 'Clean date',
        name: {
            label: 'Your name',
            placeholder: 'Enter your name',
        },
        email: {
            label: 'Your email',
            placeholder: 'Enter your email',
        },
        phone: {
            label: 'Your phone',
            placeholder: 'Enter your phone',
        },
        subject: {
            label: 'Subject',
            placeholder: 'Enter your subject',
        },
        type: {
            label: 'Type of participation',
            placeholder: 'Make a selection',
            partage: 'Partagez au sujet du juste pour aujourd\'hui',
            lecture: 'Lecture du juste pour aujourd\'hui',
        },
        message: {
            label: 'Your message',
            placeholder: 'Please include all relevant information',
        },
        send_message: 'Send Message',
        sideBar: {
            title: 'Contact information',
            email:{
                label: 'Email',
                description: 'info@podcastanonyme.com',
            },
            phone: {
                label: 'Phone',
                description: '+1 (514) 582-6431',
            },
            address: {
                label: 'Address',
                description: 'Church Très-Saint-Coeur-de-Marie\n' +
                    '2390 Bourgogne ave, Chambly, QC, Canada, J3L 2A4',
            },
            meeting: {
                label: 'Meeting',
                description: 'Wednesdays 7.30 p.m. – 9 p.m.',
            }
        }
    },
    footer:{
        title: 'PODCAST ANONYME',
        address: 'Address:  Church Très-Saint-Coeur-de-Marie 2390 Bourgogne ave, Chambly, QC, Canada, J3L 2A4',
        phone_email: 'Phone: (514) 582-6431 | Email: info@podcastanonyme.com',
        thanks: "We would like to extend our heartfelt thanks to Douglas L. from the \"Anonymous Podcast\" for his invaluable support. We strongly encourage you to pay him a visite."
    }
}

export default messages
