import React, { useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'
import appLocales from '../lang'

interface IValueInterface {
  language: string
  toggleChangeLanguage(): void
}

const InitialState = {
  language: 'fr',
}

export function flattenMessages(nestedMessages: object, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    // @ts-ignore
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      /* eslint-disable */
      // @ts-ignore
      messages[prefixedKey] = value
      /* eslint-enable */
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

// @ts-ignore
const LanguageContext = React.createContext<IValueSettingInterface>()

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(InitialState)

  const value = useMemo<IValueInterface>(
    () => ({
      language: state.language,
      toggleChangeLanguage: () => {
        setState(current => ({ ...current, language: current.language === 'fr' ? 'en' : 'fr' }))
      },
    }),
    [state, setState]
  )

  const localeConfig = appLocales[state.language]

  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider locale={localeConfig.locale} messages={flattenMessages(localeConfig.messages)}>

            {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

export default LanguageContext
