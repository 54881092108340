import {Text, Container, useMantineTheme, Title, Image} from '@mantine/core';
import '../Styles/SectionOne.scss';
import {useIntl} from "react-intl";

const SectionOne = () => {
    const theme = useMantineTheme();
    const intl = useIntl();

    const carouselContent = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundImage: `url(${teamImage})`,
        flexDirection: 'column' as 'column',
        backgroundColor: theme.colors.yellow[5],
        borderRadius: 15,
        gap: 15
    };

    return (
        <section id="section-one">
            <Container>
                <Text color="black" align="justify" mb="15px">
                    {intl.formatMessage({id: "stepOne.text.one.text"})}
                </Text>
                <Text color="black" align="justify" mb="15px">
                    {intl.formatMessage({id: "stepOne.text.two.text"})}
                </Text>
                <Text color="black" align="justify" mb="15px">
                    {intl.formatMessage({id: "stepOne.text.three.text"})}
                </Text>
                <Text color="black" align="justify" mb="15px">
                    {intl.formatMessage({id: "stepOne.text.four.text"})}
                </Text>
                <Text color="black" align="justify" mb="15px">
                    {intl.formatMessage({id: "stepOne.text.five.text"})}
                </Text>
            </Container>
        </section>
    );
};

export default SectionOne;
