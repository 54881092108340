import {Grid, Text, Container, Title, Image, Button, UnstyledButton} from '@mantine/core';
import ideaLaunchImage from '../Images/undraw_phone_call_re_hx6a.svg';
import {useIntl} from "react-intl";
import {Link} from "react-scroll";

const SectionThree = () => {
    //const theme = useMantineTheme();
    const intl = useIntl();

    return (
        <section id="section-three">
            <Container>
                <Grid justify="space-around">
                    <Grid.Col xs={6} sm={4} md={4} lg={4}>
                        <Image src={ideaLaunchImage} alt='sample2' />
                    </Grid.Col>
                    <Grid.Col xs={6} sm={8} md={8} lg={8}>
                        <div style={{ marginBottom: 20 }}>
                            <Text color="black">
                                <Title order={2}>{intl.formatMessage({id: "stepThree.title"})}</Title>
                                {intl.formatMessage({id: "stepThree.text_two"})}
                            </Text>
                            <Text color="black">
                                <a href="tel:1 855 544-6362">1 855 544-6362 </a>
                            </Text>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Text color="black">
                                {intl.formatMessage({id: "stepThree.text"})}
                            </Text>
                        </div>
                            <a href="https://naquebec.org" target="_blank" rel="noopener noreferrer"><Button color="blue">{intl.formatMessage({id: "stepThree.button"})}</Button></a>
                    </Grid.Col>
                </Grid>
            </Container>
        </section>
    );

};

export default SectionThree;
