import { useMantineTheme, Container, Grid, Text, Button, Group, Avatar, UnstyledButton, Anchor, Code } from '@mantine/core';
import {useIntl} from "react-intl";
import {Link} from "react-scroll";

const Footer = () => {
    const theme = useMantineTheme();
    const intl = useIntl();

    return (
        <footer style={{ backgroundColor: theme.colors.blue[6] }}>
            <Container>
                <Grid justify="space-around">
                    <Grid.Col xs={12} sm={8} md={8} lg={8}>

                        <Text size="xl" weight={700} color="white" mb="10px">
                            {intl.formatMessage({ id: "footer.title" })}
                        </Text>

                        <Text color="white" mb="15px">
                            {intl.formatMessage({ id: "footer.address" })}
                        </Text>
                        <Text color="white" mb="15px">
                            {intl.formatMessage({ id: "footer.phone_email" })}
                        </Text>
                        <Text color="white" mb="15px">
                            ZOOM : <a href="https://us06web.zoom.us/j/4470174809" style={{color:"white"}}>https://us06web.zoom.us/j/4470174809</a>
                        </Text>
                        <div className="fb-like" data-href="https://www.facebook.com/podcastanonyme" data-width="" data-layout="" data-action="" data-size="" data-share="true"></div>
                        <Text color="white" mb="15px">
                            {intl.formatMessage({ id: "footer.thanks" })}
                        </Text>
                        <iframe
                            style={{ borderRadius: '12px' }}
                            src="https://open.spotify.com/embed/show/0YnSBpvXBbiEdYHnARmuB9?utm_source=generator"
                            width="100%"
                            height="72"
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy">
                        </iframe>
                    </Grid.Col>
                </Grid>
                <Group position="center" mt="20px">
                    <Text color="white">© 2023 Radiko | Powered by innovation, driven by excellence.</Text>
                </Group>
            </Container>
        </footer>
    )
};

export default Footer;


const redirectToLink = (link: string): void => {
    window.open(link, '_blank');
};
